import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Fade } from '@mui/material';

import Typography from 'components/ui/Atoms/Typography';
import styles from './inputErrorStyles';

const useStyles = makeStyles({ name: 'InputError' })(styles);

const InputErrorList = (props) => {
  const {
    error = false,
    errorMessage,
    className,
    inputId,
    ...otherProps
  } = props;
  const { classes, cx } = useStyles();
  const showError = Boolean(error && errorMessage);

  return (
    <Fade
      in={showError}
      appear={showError}
      mountOnEnter
      unmountOnExit
    >
      <Typography
        variant="caption1"
        color="error"
        component="div"
        data-cy={`${inputId}-error-list`}
        className={cx(classes.root, className)}
        {...otherProps}
      >
        {Array.isArray(errorMessage) && errorMessage.length > 1 ? (
          <ul className={classes.errorList}>
            {errorMessage.map((item, index) => (
              <li key={index} className={classes.error}>{item}</li>
            ))}
          </ul>
        ) : errorMessage}
      </Typography>
    </Fade>
  );
};

InputErrorList.propTypes = {
  inputId: PropTypes.string.isRequired,
  className: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default InputErrorList;
